import {
    Button,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    makeStyles,
    MessageBar,
    MessageBarBody,
    Spinner,
    Switch,
    tokens,
} from "@fluentui/react-components";
import { DriveItem } from "@microsoft/microsoft-graph-types";
import { useEffect, useState } from "react";
import { isRunningLikeStatus, pipeline } from "../models/pipeline";
import { getFilesAndChildren } from "./FileListModalAnalyze";

type Props = {
    selectedItems: DriveItem[];
    setError: (err: string) => void;
    fetchNonSuccessPipelines: (itemIds: string[]) => Promise<pipeline[]>;
    fetchSuccessPipelines: (itemIds: string[]) => Promise<pipeline[]>;
    deleteSelection: (
        items: DriveItem[],
        sharePointItems: DriveItem[],
        deleteSp: boolean
    ) => Promise<void>;
    setShowDelete: (value: React.SetStateAction<boolean>) => void;
};

export default function FileListModalDelete({
    selectedItems,
    setError,
    fetchNonSuccessPipelines,
    fetchSuccessPipelines,
    deleteSelection,
    setShowDelete,
}: Props) {
    const [loadingDeleteFiles, setLoadingDeleteFiles] = useState(false);
    const [deleteSP, setDeleteSP] = useState(false);
    const [nbRunningAnalyses, setNbRunningAnalyses] = useState(0);
    const [itemsToDeleteDeka, setItemsToDeleteDeka] = useState<DriveItem[]>([]);
    const [itemsToDeleteSP, setItemsToDeleteSP] = useState<DriveItem[]>([]);

    useEffect(() => {
        loadFilesToDelete();
    }, []);

    const styles = useStyles();

    const loadFilesToDelete = async () => {
        setLoadingDeleteFiles(true);

        let items: DriveItem[];
        try {
            items = await getFilesAndChildren(selectedItems);
        } catch (error: any) {
            setError(error.message);
            setLoadingDeleteFiles(false);
            return;
        }

        let runningPipelines: pipeline[];
        let successPipelines: pipeline[];
        try {
            runningPipelines = (
                await fetchNonSuccessPipelines(
                    items.map((file) => file.id || "")
                )
            ).filter((p) => isRunningLikeStatus(p.status));

            successPipelines = await fetchSuccessPipelines(
                items.map((file) => file.id || "")
            );
        } catch (err: any) {
            setError("failed to fetch analyses: " + err.message);
            setLoadingDeleteFiles(false);
            return;
        }

        let deleteItems = items.filter(
            (f) => !runningPipelines.find((p) => p.teamsId === f.id)
        );

        const analyzedIds = new Set(successPipelines.map((p) => p.teamsId));

        let { deleteItemsDeka, deleteItemsSP } = deleteItems.reduce<{
            deleteItemsDeka: DriveItem[];
            deleteItemsSP: DriveItem[];
        }>(
            (acc, curr) => {
                if (curr.id) {
                    if (analyzedIds.has(curr.id)) {
                        acc.deleteItemsDeka.push(curr);
                    } else {
                        acc.deleteItemsSP.push(curr);
                    }
                }
                return acc;
            },
            { deleteItemsDeka: [], deleteItemsSP: [] }
        );

        setItemsToDeleteDeka(deleteItemsDeka);
        setItemsToDeleteSP(deleteItemsSP);
        setNbRunningAnalyses(items.length - deleteItems.length);
        setLoadingDeleteFiles(false);
    };

    const nbToDelete = deleteSP
        ? itemsToDeleteDeka.length + itemsToDeleteSP.length
        : itemsToDeleteDeka.length;

    return (
        <DialogSurface>
            <DialogBody>
                <DialogTitle>Delete Analyses</DialogTitle>
                <DialogContent>
                    <Switch
                        label="Also delete files" // files are moved to recycle bin
                        onChange={(ev, data) => {
                            setDeleteSP(data.checked);
                        }}
                    />

                    {!loadingDeleteFiles && nbRunningAnalyses !== 0 ? (
                        <MessageBar>
                            <MessageBarBody>
                                {`${nbRunningAnalyses} selected file${
                                    nbRunningAnalyses === 1 ? " is" : "s are"
                                } being analyzed and will not be deleted.`}
                            </MessageBarBody>
                        </MessageBar>
                    ) : (
                        ""
                    )}
                </DialogContent>

                <DialogActions>
                    <DialogTrigger disableButtonEnhancement>
                        <Button appearance="secondary">Cancel</Button>
                    </DialogTrigger>

                    <Button
                        appearance="primary"
                        className={styles.button}
                        onClick={() => {
                            deleteSelection(
                                itemsToDeleteDeka,
                                itemsToDeleteSP,
                                deleteSP
                            );
                            setShowDelete(false);
                        }}
                        disabled={loadingDeleteFiles || nbToDelete === 0}
                        icon={
                            loadingDeleteFiles ? <Spinner size="tiny" /> : null
                        }
                    >
                        Delete {loadingDeleteFiles ? "" : `(${nbToDelete})`}
                    </Button>
                </DialogActions>
            </DialogBody>
        </DialogSurface>
    );
}

const disabledColor = tokens.colorNeutralBackgroundDisabled;
const tokensColorStatusDangerBackground3Hover = "#b10e1c"; // exists in version @fluentui/react-components@9.48.0
const tokensColorStatusDangerBackground3Pressed = "#960b18"; // exists in version @fluentui/react-components@9.48.0

export const useStyles = makeStyles({
    button: {
        backgroundColor: tokens.colorStatusDangerBackground3,
        ":disabled": {
            backgroundColor: disabledColor,
            ":hover": {
                backgroundColor: disabledColor,
            },
            ":active": {
                backgroundColor: disabledColor + " !important",
            },
        },
        ":hover": {
            backgroundColor: tokensColorStatusDangerBackground3Hover,
        },
        ":active": {
            backgroundColor:
                tokensColorStatusDangerBackground3Pressed + " !important",
        },
    },
});
