import {
    Link,
    ProgressBar,
    Toast,
    ToastBody,
    ToastTitle,
    ToastTrigger,
    useId,
    useToastController,
} from "@fluentui/react-components";
import { useEffect } from "react";

export type deleteStatus = "in progress" | "success" | "failed";

type Props = {
    value: number;
    max: number;
    deleting: deleteStatus;
    toasterId: string;
};

export const ProgressDelete = ({ deleting, toasterId, value, max }: Props) => {
    const toastId = useId("delete");
    const { dispatchToast, updateToast } = useToastController(toasterId);

    useEffect(() => {
        if (deleting === "in progress") {
            // dispatch and update because we do not know if toast is already there
            dispatchToast(jsxToastProgress(), {
                toastId: toastId,
                timeout: -1,
                intent: "info",
            });
            updateToast({
                toastId: toastId,
                timeout: -1,
                content: jsxToastProgress(),
                intent: "info",
            });
        } else if (deleting === "success") {
            updateToast({
                toastId: toastId,
                content: jsxToastSuccess(),
                intent: "success",
            });
        } else if (deleting === "failed") {
            updateToast({
                toastId: toastId,
                content: jsxToastFailed(),
                intent: "error",
            });
        }
    }, [deleting]);

    useEffect(() => {
        max !== value &&
            updateToast({
                toastId,
                content: jsxToastProgress(),
                intent: "info",
            });
    }, [value, max]);

    const jsxToastProgress = () => (
        <Toast>
            <ToastTitle>
                Deleting ({value}/{max})
            </ToastTitle>
            <ToastBody>
                <ProgressBar value={value} max={max} />
            </ToastBody>
        </Toast>
    );

    const jsxToastSuccess = () => (
        <Toast>
            <ToastTitle
                action={
                    <ToastTrigger>
                        <Link>Dismiss</Link>
                    </ToastTrigger>
                }
            >
                Deleted
            </ToastTitle>
        </Toast>
    );

    const jsxToastFailed = () => (
        <Toast>
            <ToastTitle
                action={
                    <ToastTrigger>
                        <Link>Dismiss</Link>
                    </ToastTrigger>
                }
            >
                Something went wrong while deleting
            </ToastTitle>
        </Toast>
    );

    return <></>;
};
