export const actionRawJson = "raw_json";

export const actionDekaEntity = "deka_entity";
export const actionDekaKeysegment = "deka_keysegment";
export const actionDekaAction = "deka_action";
export const actionDekaActionEntity = "deka_action_entity";
export const actionlabelDocument = "document";
export const actionlabelTeamsDocumentId = "teams_doc_id";

export const actionPseudo = "document_pseudonymize";
export const actionPseudoAnonymized = "document_pseudonymize_anonymized";
export const actionlabelPseudo = "pseudonymization";

export const errorColor = "red";
export const successColor = "green";

export const DYNIZER_TENANT_HEADER_KEY = "dynizer-tenant";

export const ragModes: RagModeOption[] = [
    { label: "No text search", value: undefined },
    { label: "Based on LAST chat message", value: "LAST" },
    { label: "Based on FIRST chat message", value: "FIRST" },
    { label: "Based on ALL chat messages", value: "ALL" },
];

export type RagModeOption = {
    label: string;
    value: RagMode;
};

export type RagMode = "LAST" | "FIRST" | "ALL" | undefined;
