import { Icon } from "@iconify/react";
import { DriveItem } from "@microsoft/microsoft-graph-types";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import ComponentDetection from "./ComponentDetection";
import KeySegments from "./KeySegments";
import Pseudo from "./Pseudo";
import Summary from "./Summary";
import ToneAnalysis from "./ToneAnalysis";
import WordCloud from "./WordCloud";

type Props = {
    inJson: any;
    inPseudoJson: any;
    jsonLoading: boolean;
    pseudoJsonLoading: boolean;
    file: DriveItem;
    setError: (msg: string) => void;
    setSuccess: (title: string, msg: string) => void;
};

export default function Tiles({
    inJson,
    inPseudoJson,
    file,
    setError,
    setSuccess,
    jsonLoading,
    pseudoJsonLoading,
}: Props) {
    const [show, setShow] = useState(false);
    const [title, setTitle] = useState("");

    const cards = [
        {
            label: webcomponent.WWWW,
            icon: "mdi:radar",
            iconColor: "#3fbb93",
        },
        {
            label: webcomponent.Summary,
            icon: "mdi:file-document-multiple-outline",
            iconColor: "#2281F5",
        },
        {
            label: webcomponent.Pseudo,
            icon: "mdi:incognito",
            iconColor: "#f53022",
        },
        {
            label: webcomponent.WordCloud,
            icon: "mdi:cloud-outline",
            iconColor: "#f58f22",
        },
        {
            label: webcomponent.KeySegments,
            icon: "mdi:key",
            iconColor: "#f5d522",
        },
        {
            label: webcomponent.ToneAnalysis,
            icon: "mdi:account-voice",
            iconColor: "#22cef5",
        },
    ];

    let tiles = (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
            {cards.map((card) => (
                <div className="col-4 d-flex" key={card.label}>
                    <div
                        className="analysis-card"
                        onClick={() => {
                            setShow(true);
                            setTitle(card.label);
                        }}
                    >
                        <div
                            className="card-icon-container d-flex justify-content-center align-items-center"
                            style={{ color: card.iconColor }}
                        >
                            <Icon icon={card.icon} fontSize="22px" />
                        </div>
                        <div className="card-label">{card.label}</div>
                    </div>
                </div>
            ))}
        </div>
    );

    const bootstrapSpinner = (
        <div className="center" style={{ height: "150px" }}>
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    );

    const getModalbody = () => {
        if (title === webcomponent.Pseudo)
            return pseudoJsonLoading ? (
                bootstrapSpinner
            ) : (
                <Pseudo
                    inJson={inPseudoJson}
                    file={file}
                    setError={setError}
                    setSuccess={setSuccess}
                ></Pseudo>
            );
        if (jsonLoading) return bootstrapSpinner;
        if (title === webcomponent.WWWW)
            return <ComponentDetection inJson={inJson}></ComponentDetection>;
        if (title === webcomponent.Summary)
            return <Summary inJson={inJson}></Summary>;
        if (title === webcomponent.KeySegments)
            return <KeySegments inJson={inJson}></KeySegments>;
        if (title === webcomponent.WordCloud)
            return <WordCloud inJson={inJson}></WordCloud>;
        if (title === webcomponent.ToneAnalysis)
            return <ToneAnalysis inJson={inJson}></ToneAnalysis>;
    };

    return (
        <>
            {tiles}

            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="tiles-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{getModalbody()}</Modal.Body>
            </Modal>
        </>
    );
}

const webcomponent = {
    WWWW: "Analysis",
    Summary: "Summary",
    KeySegments: "Key Segments",
    WordCloud: "Word Cloud",
    ToneAnalysis: "Tone Analysis",
    Pseudo: "Anonymize",
};
