import { app, authentication } from "@microsoft/teams-js";
import { useEffect, useRef, useState } from "react";
import { v4 as newUuid } from "uuid";
import {
    actionlabelDocument,
    actionlabelTeamsDocumentId,
    actionRawJson,
    DYNIZER_TENANT_HEADER_KEY,
    RagMode,
} from "../models/const";
import { http } from "../models/http";
import { actionLabelTeamGroupId, actionLabelUserId } from "../models/pipeline";
import { Chat, Api as RagApi } from "../services/openapi/rag-axios/RagApi";

type Props = {
    tenant: string;
    fileIDs: string[];
    context: app.Context | undefined;
    ragMode: RagMode;
    openDocWebcomponent: (document_id: string) => Promise<void>;
};

const Mag = ({
    tenant,
    fileIDs,
    context,
    ragMode,
    openDocWebcomponent,
}: Props) => {
    const webcomponent = useRef<any>(null);
    const [uuid, setUuid] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setUuid(newUuid());

        webcomponent.current.data = [];
        webcomponent.current.config = {
            summary_pct: 50,
            max_tokens: 2500,
            temperature: 0.5,
            frequency_penalty: 0.5,
            top_p: 0.5,
            top_k: 10,
        };
    }, []);

    useEffect(() => {
        const currentRef = webcomponent.current;

        const handleSend = async (event: any) => {
            if (loading) return;

            if (!event?.detail?.text) return;

            setLoading(true);

            let conversationWithUserMessage = [
                ...webcomponent.current.data,
                {
                    message: event.detail.text,
                    role: "USER",
                },
            ];

            let requestBody: Chat = {
                text: event.detail.text,
                frequency_penalty: event.detail.frequency_penalty,
                max_tokens: event.detail.max_tokens,
                summary_pct: event.detail.summary_pct,
                temperature: event.detail.temperature,
                top_k: event.detail.top_k,
                top_p: event.detail.top_p,
                conversation_id: uuid,
                api_url: `https://${tenant}.dynizer.com/api/v2`,
                rag_type: event.detail.dynizerAnnotation ? "ANNOTATE" : "TEXT",
                rag_mode: ragMode,
                document_selector: {
                    action: actionRawJson,
                    action_label: actionlabelDocument,
                    operation: "OR",
                    text_search: ragMode !== undefined,
                    filter: fileIDs.length
                        ? fileIDs.map((id) => ({
                              action_label: actionlabelTeamsDocumentId,
                              value: id,
                          }))
                        : context?.team?.groupId
                        ? [
                              {
                                  action_label: actionLabelTeamGroupId,
                                  value: context?.team?.groupId,
                              },
                          ]
                        : [
                              {
                                  action_label: actionLabelUserId,
                                  value: context?.user?.id,
                              },
                          ],
                },
            };

            let dynizerJwtToken = await authentication.getAuthToken();

            try {
                let response = await ragApi.cohere.chat(requestBody, {
                    headers: {
                        Authorization: `Bearer ${dynizerJwtToken}`,
                        [DYNIZER_TENANT_HEADER_KEY]: tenant,
                    },
                });
                if (webcomponent.current) {
                    webcomponent.current.data = response.data.conversation;
                    webcomponent.current.citations = response.data.citations;
                    webcomponent.current.citationDocuments =
                        response.data.citation_documents;
                }
            } catch {
                webcomponent.current.data = [
                    ...conversationWithUserMessage,
                    {
                        message:
                            "Something went wrong when sending the message",
                        role: "CHATBOT",
                        tool_calls: null,
                    },
                ];
            }

            setLoading(false);
        };

        const handleGetSelectedDocument = async (event: any) => {
            let document_id = event?.detail;

            webcomponent.current.documentInsightsLoading = true;
            try {
                let response = await downloadObject("document", document_id);
                if (webcomponent.current) {
                    webcomponent.current.documentInsights =
                        JSON.parse(response).document;
                }
            } catch (err: any) {
                console.error("failed to get document json", err);
            }
            webcomponent.current.documentInsightsLoading = false;
        };

        const handleDownloadSelectedDocument = async (event: any) => {
            let document_id = event?.detail;

            // try-catch
            await openDocWebcomponent(document_id);
        };

        currentRef.addEventListener("submit", handleSend);
        currentRef.addEventListener(
            "getSelectedDocument",
            handleGetSelectedDocument
        );
        currentRef.addEventListener(
            "downloadSelectedDocument",
            handleDownloadSelectedDocument
        );

        return () => {
            currentRef.removeEventListener("submit", handleSend);
            currentRef.removeEventListener(
                "getSelectedDocument",
                handleGetSelectedDocument
            );
            currentRef.removeEventListener(
                "downloadSelectedDocument",
                handleDownloadSelectedDocument
            );
        };
    }, [loading, tenant, fileIDs, uuid]);

    const setXhttpHeaders = (xhttp: XMLHttpRequest, token: string) => {
        xhttp.setRequestHeader("Authorization", `Bearer ${token}`);
        xhttp.setRequestHeader("Content-Type", "application/octet-stream");
        xhttp.setRequestHeader(DYNIZER_TENANT_HEADER_KEY, tenant);
    };

    const buildXhttpError = (xhttp: XMLHttpRequest, url: string) => {
        let responseText =
            xhttp.responseType === "text" || xhttp.responseType === ""
                ? xhttp.responseText
                : "";

        return `Request to ${url} failed with status ${xhttp.status}${
            xhttp.statusText ? ", " + xhttp.statusText : ""
        }${responseText ? ": " + responseText : ""}`;
    };

    const downloadObject = async (type: string, uuid: any): Promise<string> => {
        return new Promise(async (resolve, reject) => {
            let xhttp = new XMLHttpRequest();
            let url = `/dynizer-object-api/api/v2/objects/download/${type}/${uuid}`;
            let dynizerJwtToken = await authentication.getAuthToken();

            xhttp.open("GET", url, true);
            setXhttpHeaders(xhttp, dynizerJwtToken);

            xhttp.onreadystatechange = () => {
                if (xhttp.readyState == XMLHttpRequest.DONE) {
                    if (xhttp.status === http.OK) {
                        let res = xhttp.responseText;
                        resolve(res);
                    } else {
                        let error = buildXhttpError(xhttp, url);
                        reject(error);
                    }
                }
            };

            xhttp.send();
        });
    };

    const ragApi = new RagApi({
        baseURL: "/mag-api",
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
        },
    });

    return <consono-rag ref={webcomponent}></consono-rag>;
};

export default Mag;
