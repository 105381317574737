import {
    Breadcrumb,
    BreadcrumbButton,
    BreadcrumbDivider,
    BreadcrumbItem,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogBody,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Tooltip,
} from "@fluentui/react-components";
import { ArrowClockwiseFilled } from "@fluentui/react-icons";
import { Icon } from "@iconify/react";
import { Providers, ProviderState } from "@microsoft/mgt-element";
import { Channel, DriveItem } from "@microsoft/microsoft-graph-types";
import { app, authentication } from "@microsoft/teams-js";
import { Fragment, useEffect, useState } from "react";
import ReactModal from "react-bootstrap/Modal";
import urlJoin from "url-join";
import {
    DYNIZER_TENANT_HEADER_KEY,
    RagMode,
    RagModeOption,
    ragModes,
} from "../models/const";
import { http } from "../models/http";
import { ChannelListResponse, DriveItemsResponse } from "../models/ms-graph";
import {
    getGroupIdFromContext,
    mimeTypeToDynizerType,
    pipeline,
    status,
} from "../models/pipeline";
import FileCustom from "./FileCustom";
import FileListModalAnalyze from "./FileListModalAnalyze";
import FileListModalDelete from "./FileListModalDelete";
import FileListModalRag from "./FileListModalRag";
import Loading from "./Loading";
import Mag from "./Mag";
import { deleteStatus } from "./ProgressDelete";
import { channelView } from "./Settings";
import VirtualScroller from "./VirtualScroller";

type Props = {
    reload: boolean;
    fileClick: (file: DriveItem) => void;
    startAnalysis: (files: DriveItem[]) => void;
    toRootFolder: () => void;
    fetchAndSetPipelines: (
        items: DriveItem[],
        append: boolean
    ) => Promise<void>;
    fetchPipelines: (items: DriveItem[]) => Promise<pipeline[]>;
    setError: (err: string) => void;
    currentFolder: DriveItem[];
    pipelines: pipeline[];
    selectedFile: DriveItem | undefined;
    context: app.Context | undefined;
    tenant: string;
    view: string;
    loading: boolean;
    setLoading: (loading: boolean) => void;
    filterActive: boolean;
    catchXhttpNon2XX: (xhttp: XMLHttpRequest, url: string) => void;
    deleteSelection: (
        items: DriveItem[],
        sharePointItems: DriveItem[],
        deleteSp: boolean
    ) => Promise<void>;
    uploading: boolean;
    deleteStatus: deleteStatus;
    fetchSuccessPipelines: (itemIds: string[]) => Promise<pipeline[]>;
    fetchNonSuccessPipelines: (itemIds: string[]) => Promise<pipeline[]>;
    openDocWebcomponent: (document_id: string) => Promise<void>;
};

export default function FileList({
    reload,
    fileClick,
    startAnalysis,
    toRootFolder,
    fetchAndSetPipelines,
    fetchPipelines,
    setError,
    currentFolder,
    pipelines,
    selectedFile,
    context,
    tenant,
    view,
    loading,
    setLoading,
    filterActive,
    catchXhttpNon2XX,
    deleteSelection,
    uploading,
    deleteStatus,
    fetchSuccessPipelines,
    fetchNonSuccessPipelines,
    openDocWebcomponent,
}: Props) {
    const [nextPageUrl, setNextPageUrl] = useState<string | undefined>("");
    const [checkAllItems, setCheckAllItems] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);
    const [showRagChat, setShowRagChat] = useState(false);
    const [showAnalyzeFileList, setShowAnalyzeFileList] = useState(false);
    const [showRagFileList, setShowRagFileList] = useState(false);
    const [showPowerBIDialog, setShowPowerBIDialog] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [ragFilesID, setRagFilesID] = useState<string[]>([]);
    const [channelFolders, setChannelFolders] = useState<DriveItem[]>([]);
    const [items, setItems] = useState<DriveItem[]>([]);
    const [selectedItems, setSelectedItems] = useState<DriveItem[]>([]);
    const [breadcrumbs, setBreadcrumbs] = useState<DriveItem[]>([]);
    const [ragMode, setRagMode] = useState<RagModeOption>(
        ragModes.find((mode) => mode.value === "LAST") || ragModes[0]
    );

    useEffect(() => {
        if (!context) return;

        loadItems(false);
        setCheckAllItems(false);
    }, [currentFolder]);

    useEffect(() => {
        if (!context) return;

        loadItems(true);
        setCheckAllItems(false);
    }, [context, reload]);

    useEffect(() => {
        setSelectedItems(
            checkAllItems ? items.filter((item) => hasCheckBox(item)) : []
        );
    }, [checkAllItems]);

    useEffect(() => {
        setBreadcrumbs(currentFolder);
    }, [items]);

    const handleItemCheck = (item: DriveItem, checked: boolean) => {
        setSelectedItems(
            checked
                ? [...selectedItems, item]
                : selectedItems.filter(
                      (selectedItem) => item.id !== selectedItem.id
                  )
        );
    };

    const handleCheckAll = () => {
        setCheckAllItems(!checkAllItems);
    };

    const handleStartAnalysis = (files: DriveItem[]) => {
        startAnalysis(files);
        setShowAnalyzeFileList(false);
    };

    const handleStartRag = (
        files: DriveItem[],
        allSelectedInModal: boolean
    ) => {
        if (allSelectedInModal && allSelectedAtRoot) {
            files = []; // an empty file list optimizes the dql query
        }

        setRagFilesID(files.map((file) => file.id || ""));
        setShowRagFileList(false);
        setShowRagChat(true);
    };

    let noFilesToShow =
        items.length === 0 ||
        (filterActive &&
            !items.find(
                (item: DriveItem) => hasValidMimeType(item) || !item.file
            ));

    let noRelevantFiles =
        items.length === 0 ||
        !items.find((item: DriveItem) => hasValidMimeType(item) || !item.file);

    let groupId = getGroupIdFromContext(context);

    let showChannelList =
        view === channelView && groupId && !currentFolder.length;

    let allSelectedAtRoot =
        !currentFolder.length && selectedItems.length === items.length;

    const handleError = (err: any) => {
        if (err.statusCode === 401) {
            Providers.globalProvider.setState(ProviderState.SignedOut);
        }
        setError(err.message);
    };

    const loadItems = async (reloadChannels: boolean) => {
        setLoading(true);
        setSelectedItems([]);
        setCheckAllItems(false);

        try {
            if (showChannelList) {
                if (!reloadChannels && channelFolders.length > 0) {
                    setItems(channelFolders);
                    return;
                }

                const itemsResponse = await fetchChannels(groupId || "");
                const channelList = itemsResponse.value;

                setNextPageUrl(itemsResponse["@odata.nextLink"]);

                const channelFolderList = await fetchChannelFolders(
                    channelList,
                    groupId || ""
                );
                setItems(channelFolderList);
                setChannelFolders(channelFolderList);
            } else {
                const itemsResponse: DriveItemsResponse = await fetchDriveItems(
                    groupId,
                    view,
                    currentFolder,
                    channelFolders,
                    20
                );

                setNextPageUrl(itemsResponse["@odata.nextLink"]);
                setItems(itemsResponse.value);

                await fetchAndSetPipelines(itemsResponse.value, false);
            }
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
            setInitialLoading(false);
        }
    };

    const loadNextPage = async () => {
        if (!nextPageUrl) return;

        setLoading(true);

        try {
            if (showChannelList) {
                const itemsResponse: ChannelListResponse = await fetchNextPage(
                    nextPageUrl
                );
                const channelsNext = itemsResponse.value;

                const nextChannelFolders = await fetchChannelFolders(
                    channelsNext,
                    groupId || ""
                );

                setItems((prevFolders) => [
                    ...prevFolders,
                    ...nextChannelFolders,
                ]);
                setChannelFolders((prevFolders) => [
                    ...prevFolders,
                    ...nextChannelFolders,
                ]);

                setNextPageUrl(itemsResponse["@odata.nextLink"]);
            } else {
                const itemsResponse: DriveItemsResponse = await fetchNextPage(
                    nextPageUrl
                );

                setItems((prevItems) => [...prevItems, ...itemsResponse.value]);
                setNextPageUrl(itemsResponse["@odata.nextLink"]);

                await fetchAndSetPipelines(itemsResponse.value, true);
            }
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const setRagModeFromString = (mode: RagMode) => {
        let ragMode = ragModes.find((rm) => rm.value === mode);

        ragMode && setRagMode(ragMode);
    };

    const openPowerBI = async () => {
        var xhttp = new XMLHttpRequest();
        var dashboardUrl = `/powerbi/dashboard-id`;

        xhttp.open("GET", dashboardUrl, true);

        xhttp.setRequestHeader(DYNIZER_TENANT_HEADER_KEY, tenant);

        xhttp.onreadystatechange = () => {
            if (xhttp.readyState == 4) {
                if (xhttp.status === http.OK) {
                    let dashboardID = xhttp.responseText;
                    openDashboard(dashboardID);
                } else {
                    catchXhttpNon2XX(xhttp, dashboardUrl);
                }
            }
        };

        xhttp.send();
    };

    const openDashboard = async (dashboardID: string) => {
        let groupId = getGroupIdFromContext(context);

        let jwt = await authentication.getAuthToken();

        let teamID = groupId ? groupId : "user";
        let userID = context?.user?.id;

        const table = "query_params";
        const columnTeamID = "team_id";
        const columnUserID = "user_id";
        const columnJwt = "jwt";

        let url = `https://app.powerbi.com/groups/b1f947c8-d5c6-419b-a33c-e34c268a9c56/reports/${dashboardID}/ReportSection?experience=power-bi`;

        let filter = `&filter=${table}/${columnTeamID} eq '${teamID}' and ${table}/${columnUserID} eq '${userID}' and ${table}/${columnJwt} eq '${jwt}'`;

        window.open(url + filter, "_blank");
    };

    const jsxButtons = (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
            }}
        >
            <div style={{ marginLeft: "3px" }}>
                <Checkbox
                    checked={checkAllItems}
                    onChange={handleCheckAll}
                    disabled={noRelevantFiles || loading}
                />
            </div>

            <div
                style={{
                    display: "flex",
                    flexGrow: 1,
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "5px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        gap: "7px",
                    }}
                >
                    <Button
                        appearance="primary"
                        icon={<Icon icon="mdi:play" />}
                        disabled={uploading || !selectedItems.length}
                        onClick={() => {
                            setShowAnalyzeFileList(true);
                        }}
                    >
                        Analyze{" "}
                    </Button>

                    <Button
                        appearance="primary"
                        onClick={() => {
                            setShowRagFileList(true);
                        }}
                        icon={<Icon icon="mdi:forum" />}
                    >
                        MAG Chat
                    </Button>

                    <Tooltip content="Power BI Dashboard" relationship="label">
                        <Button
                            onClick={() => {
                                setShowPowerBIDialog(true);
                            }}
                            icon={<Icon icon="mdi:monitor-dashboard" />}
                            size="medium"
                            appearance="primary"
                        >
                            Power BI
                        </Button>
                    </Tooltip>

                    <Dialog
                        open={showPowerBIDialog}
                        onOpenChange={(event, data) =>
                            setShowPowerBIDialog(data.open)
                        }
                    >
                        <DialogSurface>
                            <DialogBody>
                                <DialogTitle>Power BI Dashboard</DialogTitle>

                                <DialogActions>
                                    <DialogTrigger disableButtonEnhancement>
                                        <Button appearance="secondary">
                                            Cancel
                                        </Button>
                                    </DialogTrigger>
                                    <Button
                                        appearance="primary"
                                        onClick={() => {
                                            openPowerBI();
                                        }}
                                    >
                                        Open Dashboard
                                    </Button>
                                </DialogActions>
                            </DialogBody>
                        </DialogSurface>
                    </Dialog>
                </div>
                <div
                    style={{
                        display: "flex",
                        gap: "7px",
                        justifyContent: "flex-end",
                    }}
                >
                    <Tooltip content="Delete Analyses" relationship="label">
                        <Button
                            appearance="subtle"
                            size="small"
                            onClick={() => {
                                setShowDelete(true);
                            }}
                            icon={<Icon icon="mdi:trash-can-outline" />}
                            disabled={
                                !selectedItems.length ||
                                uploading || // prevent deleting a file that is about to be analyzed
                                deleteStatus === "in progress"
                            }
                        ></Button>
                    </Tooltip>

                    <Tooltip content="Refresh" relationship="label">
                        <Button
                            appearance="subtle"
                            size="small"
                            onClick={() => {
                                if (loading) return;
                                loadItems(true);
                            }}
                            icon={<ArrowClockwiseFilled />}
                        />
                    </Tooltip>
                </div>
            </div>
        </div>
    );

    const jsxNoFiles = (
        <div className="center">
            {items.length === 0 ? (
                <div>Folder is empty</div>
            ) : (
                <div>No relevant files</div>
            )}
        </div>
    );

    const jsxFileList = items
        .filter((item) => !filterActive || !item.file || hasValidMimeType(item))
        .map((item) => (
            <FileCustom
                file={item}
                checked={!!selectedItems.find((el) => el.id === item.id)}
                checkChange={handleItemCheck}
                onClick={fileClick}
                key={item.id}
                pipeline={pipelines.find(
                    (pipeline) => pipeline.teamsId === item.id
                )}
                selected={selectedFile?.id === item.id}
            ></FileCustom>
        ));

    const jsxBreadcrumb = () => {
        let crumbs = [
            <Fragment key={"root-fragment"}>
                <BreadcrumbItem key={"root"}>
                    <BreadcrumbButton
                        onClick={() => {
                            toRootFolder();
                        }}
                        disabled={loading || !breadcrumbs.length}
                    >
                        {!groupId
                            ? "My Files"
                            : view === channelView
                            ? "Channels"
                            : context?.team?.displayName}
                    </BreadcrumbButton>
                </BreadcrumbItem>
                {breadcrumbs.length !== 0 && <BreadcrumbDivider key={"div"} />}
            </Fragment>,
            ...breadcrumbs.map((item, index) => (
                <Fragment key={`breadcrumb-fragment-${item.id}`}>
                    <BreadcrumbItem key={item.id}>
                        <BreadcrumbButton
                            onClick={() => {
                                if (index !== breadcrumbs.length - 1)
                                    fileClick(item);
                            }}
                            disabled={loading}
                        >
                            {item.name}
                        </BreadcrumbButton>
                    </BreadcrumbItem>{" "}
                    {index != breadcrumbs.length - 1 && (
                        <BreadcrumbDivider key={"div" + item.id} />
                    )}
                </Fragment>
            )),
        ];

        return (
            <div style={{ overflow: "auto" }}>
                <Breadcrumb
                    aria-label="Folder navigation breadcrumbs"
                    size="medium"
                >
                    {crumbs}
                </Breadcrumb>
            </div>
        );
    };

    return (
        <>
            <Dialog
                open={showAnalyzeFileList}
                onOpenChange={(event, data) =>
                    setShowAnalyzeFileList(data.open)
                }
            >
                <FileListModalAnalyze
                    fetchPipelines={fetchPipelines}
                    selectedItems={
                        !selectedItems.length && selectedFile
                            ? [selectedFile]
                            : selectedItems
                    }
                    startAnalysis={handleStartAnalysis}
                    setError={setError}
                ></FileListModalAnalyze>
            </Dialog>

            <Dialog
                open={showRagFileList}
                onOpenChange={(event, data) => setShowRagFileList(data.open)}
            >
                <FileListModalRag
                    fetchPipelines={fetchPipelines}
                    selectedItems={
                        !selectedItems.length && selectedFile
                            ? [selectedFile]
                            : selectedItems
                    }
                    startMag={handleStartRag}
                    setError={setError}
                    ragMode={ragMode}
                    setRagMode={setRagModeFromString}
                ></FileListModalRag>
            </Dialog>

            <Dialog
                open={showDelete}
                onOpenChange={(event, data) => setShowDelete(data.open)}
            >
                <FileListModalDelete
                    deleteSelection={deleteSelection}
                    fetchNonSuccessPipelines={fetchNonSuccessPipelines}
                    fetchSuccessPipelines={fetchSuccessPipelines}
                    selectedItems={selectedItems}
                    setError={setError}
                    setShowDelete={setShowDelete}
                ></FileListModalDelete>
            </Dialog>

            <ReactModal
                show={showRagChat}
                onHide={() => setShowRagChat(false)}
                dialogClassName="tiles-modal"
            >
                <ReactModal.Header closeButton>
                    <ReactModal.Title>MAG Chat</ReactModal.Title>
                </ReactModal.Header>
                <ReactModal.Body>
                    <Mag
                        tenant={tenant}
                        fileIDs={ragFilesID}
                        context={context}
                        ragMode={ragMode.value}
                        openDocWebcomponent={openDocWebcomponent}
                    ></Mag>
                </ReactModal.Body>
            </ReactModal>

            <div style={{ marginBottom: "7px" }}>{jsxButtons}</div>

            {!!breadcrumbs.length && <div>{jsxBreadcrumb()}</div>}

            {initialLoading ? (
                <Loading />
            ) : noFilesToShow && !nextPageUrl ? (
                jsxNoFiles
            ) : (
                <VirtualScroller
                    loading={loading}
                    jsxItems={jsxFileList}
                    loadNextPage={loadNextPage}
                    hasMore={!!nextPageUrl}
                    nbLoadedFiles={items.length}
                ></VirtualScroller>
            )}
        </>
    );
}

export const canBeAnalyzed = (item: DriveItem, pipelines: pipeline[]) => {
    return (
        hasValidMimeType(item) &&
        !pipelines.find((pipeline) => pipeline.teamsId === item.id)
    );
};

export const isAnalyzed = (item: DriveItem, pipelines: pipeline[]) => {
    return (
        hasValidMimeType(item) &&
        pipelines.find(
            (pipeline) =>
                pipeline.teamsId === item.id &&
                pipeline.status === status.SUCCESS
        )
    );
};

export const hasCheckBox = (item: DriveItem) => {
    return item.folder || hasValidMimeType(item);
};

export const hasValidMimeType = (file: DriveItem) =>
    !!file.file?.mimeType && !!mimeTypeToDynizerType[file.file?.mimeType];

const fetchChannels = async (groupId: string) => {
    let response: Promise<ChannelListResponse> =
        Providers.globalProvider.graph.client
            .api(`/teams/${groupId}/allChannels?$select=id`)
            .get();

    return response;
};

const fetchChannelFolders = async (
    channelsNext: Channel[],
    groupId: string
) => {
    return Promise.all(
        channelsNext.map(async (channel) => {
            const filesFolder: DriveItem =
                await Providers.globalProvider.graph.client
                    .api(`/teams/${groupId}/channels/${channel.id}/filesFolder`)
                    .get();
            return filesFolder;
        })
    );
};

const fetchDriveItems = (
    groupId: string | undefined,
    view: string,
    currentFolder: DriveItem[],
    channelFolders: DriveItem[],
    top: number
) => {
    let url = "";

    if (groupId) {
        if (view === channelView) {
            const itemID = currentFolder[currentFolder.length - 1].id;
            const channelFolder = channelFolders.find(
                (channelFolder) => channelFolder.id === currentFolder[0].id
            );
            const driveID = channelFolder?.parentReference?.driveId;
            url = `drives/${driveID}/items/${itemID}/children`;
        } else {
            url = currentFolder.length
                ? urlJoin(
                      `groups/${groupId}/drive/root:/`,
                      ...currentFolder.map((folder) => folder.name || "")
                  ) + ":/children"
                : `groups/${groupId}/drive/root/children`;
        }
    } else {
        url = currentFolder.length
            ? urlJoin(
                  `me/drive/root:/`,
                  ...currentFolder.map((folder) => folder.name || "")
              ) + ":/children"
            : `me/drive/root/children`;
    }

    return Providers.globalProvider.graph.client.api(url).top(top).get();
};

const fetchNextPage = async (nextPageUrl: string) => {
    return Providers.globalProvider.graph.client.api(nextPageUrl).get();
};
